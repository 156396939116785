import React, { useState } from 'react';
// Local Imports
import Button from '../../../../../../components/button';
import {
  primaryColor,
  yellow,
} from '../../../../../../utils/style/GlobalVariables';
import TextField from '../../../../../../components/inputField';
import ApiController from '../../../../../../utils/network/api';
import CustomModal from '../../../../../../components/Modal/Modal';
import { Styles } from '../../../../../../utils/style/GlobalStyles';

function GenerateAIPost({ setContent, setNewPost, content }) {
  // STATES
  const [generatePostError, setGeneratePostError] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generatePost, setGeneratePost] = useState({
    blogTopic: '',
    blogSections: '',
    blogWords: '',
    blogKeywords: [],
  });

  // ONCHANGE FOR GENERATE POST
  const generatePostOnChange = (e) => {
    setGeneratePostError('');
    const { name, value } = e.target;
    if (name === 'blogKeywords') {
      setGeneratePost((prev) => ({
        ...prev,
        [name]: value?.replace(/\s+/g, ',')?.replace(/,\s*$/, ' '),
      }));
    } else {
      setGeneratePost((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Check Blog Content
  const checkBlogContent = () => {
    const isEmpty =
      Object.values(generatePost).every((value) => value !== '') &&
      generatePost.blogKeywords.length > 0;
    if (!isEmpty) {
      setGeneratePostError('Please fill all the fields');
      return;
    }
    if (content !== '') {
      setIsOpenModal(true);
      return;
    } else {
      generateBlog();
    }
  };

  // GENERATE BLOG
  const generateBlog = () => {
    const isEmpty =
      Object.values(generatePost).every((value) => value !== '') &&
      generatePost.blogKeywords.length > 0;

    if (!isEmpty) {
      setGeneratePostError('Please fill all the fields');
      return;
    }
    let payload = {
      topics: [generatePost?.blogTopic],
      keywords:
        generatePost?.blogKeywords?.length > 0
          ? generatePost?.blogKeywords
              ?.trim()
              ?.split(',')
              ?.filter((item) => item !== '' && item !== ' ')
          : null,
      parts: generatePost?.blogSections,
      words: generatePost?.blogWords,
    };
    setLoading(true);
    ApiController.generateBlog(payload, (response) => {
      if (response.success) {
        setNewPost((prev) => ({
          ...prev,
          title: response?.data?.title,
          customURI: response?.data?.title
            ?.toLowerCase()
            ?.replace(/[^a-zA-Z ]/g, '')
            ?.replace(/\s+/g, '-'),
        }));
        setContent(response?.data?.body);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div
        className={`pb-5 px-4 flex flex-col border-b border-[${primaryColor}]`}
      >
        <div className='w-full'>
          <TextField
            label={'Blog Topic'}
            type='text'
            name='blogTopic'
            style={{
              borderWidth: '1px',
              borderColor: yellow,
            }}
            value={generatePost?.blogTopic}
            onChange={(e) => generatePostOnChange(e)}
          />
        </div>
        <div className='w-full'>
          <TextField
            label={'Blog Sections'}
            type='text'
            name='blogSections'
            style={{
              borderWidth: '1px',
              borderColor: yellow,
            }}
            value={generatePost?.blogSections}
            onChange={(e) => generatePostOnChange(e)}
          />
        </div>
        <div className='w-full'>
          <TextField
            label={'Blog Words'}
            type='text'
            name='blogWords'
            style={{
              borderWidth: '1px',
              borderColor: yellow,
            }}
            value={generatePost?.blogWords}
            onChange={(e) => generatePostOnChange(e)}
          />
        </div>
        <div className='w-full'>
          <TextField
            label={'Blog Keywords'}
            type='text'
            name='blogKeywords'
            style={{
              borderWidth: '1px',
              borderColor: yellow,
            }}
            value={generatePost?.blogKeywords}
            onChange={(e) => generatePostOnChange(e)}
          />
        </div>
        <div className='flex justify-between gap-2 mt-3'>
          <p className='text-red-500 text-xs'>{generatePostError}</p>
          <Button
            height={37}
            style={{
              color: yellow,
              fontFamily: 'Inter-Medium',
              fontSize: 13,
              textTransform: 'none',
              borderRadius: 8,
              boxShadow: 'none',
              borderColor: yellow,
            }}
            component='span'
            variant='outlined'
            onClick={checkBlogContent}
            loading={loading}
          >
            {loading ? '' : 'Generate'}
          </Button>
        </div>
      </div>
      <CustomModal
        open={isOpenModal}
        close={() => setIsOpenModal(false)}
        title={'Delete Post Content'}
        width={500}
      >
        <div className='mt-[10px] pb-1 pl-1 pr-2'>
          <p className='text-sm text-white'>
            Are you sure you want to delete previous blog content and generate
            new blog post?
          </p>
        </div>

        {/* Buttons */}
        <div className='flex items-center justify-end gap-5 mt-5'>
          <div
            onClick={() => setIsOpenModal(false)}
            className='text-white text-sm cursor-pointer'
          >
            <p style={Styles.cancelBtn}>Cancel</p>
          </div>
          <Button
            className={`!px-5 text-sm !normal-case`}
            style={Styles.activeBtn}
            onClick={() => {
              setIsOpenModal(false);
              setDeleteBlogContent(true);
              setNewPost((prev) => ({
                ...prev,
                customURI: 'enter-uri',
                title: '',
              }));
              generateBlog();
            }}
          >
            Generate New Blog
          </Button>
        </div>
      </CustomModal>
    </React.Fragment>
  );
}

export default GenerateAIPost;
