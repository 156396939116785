import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import {
  gray,
  primaryColor,
  red,
  secondaryColor,
  white,
  yellow,
} from '../../../../../../utils/style/GlobalVariables';
import TextField from '../../../../../../components/inputField';
import Button from '../../../../../../components/button';
import PageLinking from '../../../../../../components/Editor/PageLinking';
import Radio from '../../../../../../components/RadioButton/Radio';
import GenerateAIPost from './GenerateAIPost';
import BlogTags from './BlogTags';
import { useNavigate } from 'react-router-dom';
import DragDropFile from '../../../../../../components/DragDropFile/DragDropFile';
import DeleteBlogPage from '../DeleteBlogUI';
import ApiController from '../../../../../../utils/network/api';

function BlogTabs({
  newPost,
  setNewPost,
  backendError,
  setContent,
  handleOnChange,
  setDeletedIds,
  content,
  newPostMedia,
  setNewPostMedia,
  tags,
  setTags,
  links,
  setLinks,
  blogData,
  id,
}) {
  // NAVIGATION
  const navigate = useNavigate();
  // STATES
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newPostMediaError, setNewPostMediaError] = useState({
    type: '',
    message: '',
  });

  const open = Boolean(anchorEl);
  const visibilityId = open ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [value, setValue] = useState(0);
  const handleTabValue = (event, newValue) => {
    setValue(newValue);
  };

  // HANDLE VISIBILITY CHANGE
  const handleVisibilityChange = (event) => {
    setNewPost((prevData) => ({
      ...prevData,
      visibility: event.target.value,
    }));
  };

  // HANDLE DRAG & DROP FEATURE IMAGE
  const handleDropFeatureImage = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files[0]?.size < 5e6) {
      setNewPostMedia((prevData) => ({
        ...prevData,
        featureImage: files[0],
        featureImgForPayload: files[0],
        featureFrom: 'onChange',
      }));
      setNewPostMediaError({ type: '', message: '' });
    } else {
      setNewPostMediaError({
        type: 'featureImage',
        message: 'The image must not be greater than 5MB.',
      });
    }
  };
  // HANDLE REMOVE IMAGE
  const handleRemoveImage = (file, ImageIndex) => {
    const updatedImagesList = newPost?.postImages?.filter((file, index) => {
      return index !== ImageIndex;
    });
    setNewPost((prev) => ({
      ...prev,
      postImages: [...updatedImagesList],
    }));
  };

  // HANDLE CHANGE FEATURE IMAGE
  const handleOnChangeFeatureImage = (file) => {
    if (file[0]?.size < 5e6) {
      const files = Array.from(file);
      setNewPostMedia((prevData) => ({
        ...prevData,
        featureImage: file[0],
        featureImgForPayload: file[0],
        featureFrom: 'onChange',
      }));
      setNewPostMediaError({ type: '', message: '' });
    } else {
      setNewPostMediaError({
        type: 'featureImage',
        message: 'The image must not be greater than 5MB.',
      });
    }
  };

  // Handle onChange URL
  const handleOnChangeURL = (event) => {
    const inputValue = event.target.value;
    const prefix = newPost.url;
    const regex = /[^a-zA-Z0-9-]+/g;
    const value = inputValue.split('/')[5];
    if (!regex.test(value) || value === '') {
      if (inputValue.startsWith(prefix)) {
        const cleanedValue = value?.toLowerCase()?.replace(/-+/g, '-');
        setNewPost((prev) => ({
          ...prev,
          customURI: inputValue
            .substring(prefix.length)
            .replace(value, cleanedValue),
        }));
      }
      setNewPostMediaError({
        type: '',
        message: '',
      });
    } else {
      setNewPostMediaError({
        type: 'uriError',
        message: "You can't use underscores and special characters",
      });
    }
  };

  // HANDLE DELETE BLOG PAGE
  const handleOnDelete = (id) => {
    setDeleteLoading(true);
    ApiController.deleteBlogPageCall(id, (response) => {
      if (response?.success) {
        setDeleteLoading(false);
        setDeleteError('');
        setIsOpenDeleteModal(false);
        navigate('/pages');
      } else {
        setDeleteLoading(false);
        setDeleteError(response?.message);
      }
    });
  };

  // HANDLE CLOSE DELETE MODAL
  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
    setDeleteError('');
    setDeleteLoading(false);
  };

  return (
    <React.Fragment>
      <Tabs
        TabIndicatorProps={{
          style: {
            background: yellow,
          },
        }}
        value={value}
        onChange={handleTabValue}
        aria-label='basic tabs example'
        sx={{ borderBottom: `1px solid ${primaryColor}` }}
      >
        <Tab
          style={{
            textTransform: 'none',
            fontSize: 13,
            fontFamily: 'Inter-Medium',
            color: value === 0 ? white : gray,
          }}
          label='Post'
          {...a11yProps(0)}
        />
        <Tab
          style={{
            textTransform: 'none',
            fontSize: 13,
            fontFamily: 'Inter-Medium',
            color: value === 1 ? white : gray,
          }}
          label='Block'
          {...a11yProps(1)}
        />
        <Tab
          style={{
            textTransform: 'none',
            fontSize: 13,
            fontFamily: 'Inter-Medium',
            color: value === 2 ? white : gray,
          }}
          label='SEO'
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <div className='h-[calc(100vh-132px)] overflow-auto'>
          <div className={`py-5 px-4 border-b border-[${primaryColor}]`}>
            <p className='text-sm'>Summary</p>
            <div className='mt-5 flex flex-col gap-5'>
              <p className='text-sm flex justify-between'>
                <span style={{ color: gray }}>Visibility</span>
                <span
                  style={{ color: yellow }}
                  className='underline cursor-pointer'
                  onClick={handleClick}
                >
                  {newPost?.visibility === 'public' ? 'Public' : 'Private'}
                </span>
              </p>

              <div className='flex justify-between items-center'>
                <p className='text-sm flex justify-between'>
                  <span style={{ color: gray }}>Publish</span>
                </p>
                <div className='flex justify-start item-end'>
                  <input
                    type='date'
                    name='publishDate'
                    onChange={(event) => handleOnChange(event)}
                    value={newPost?.publishDate}
                    className={`bg-transparent hideIcon -mr-3 text-[${yellow}] shadow-none focus:shadow-none focus:border-b focus:border-[${yellow}]`}
                  />
                </div>
              </div>
              <p className='text-sm flex justify-between flex-col'>
                <span style={{ color: gray }}>URl</span>
                <input
                  className={`bg-transparent text-[${yellow}] shadow-none focus:shadow-none focus:border-b focus:border-[${yellow}]`}
                  type='text'
                  value={`${newPost?.url}${newPost?.customURI}`}
                  onChange={handleOnChangeURL}
                />
                <span className={`text-xs mt-1 text-red-500`}>
                  {newPostMediaError.type === 'uriError' &&
                    newPostMediaError.message}
                  {backendError?.non_field_errors !== '' &&
                    backendError?.non_field_errors}
                </span>
              </p>
            </div>
          </div>
          {!id ? (
            <GenerateAIPost
              setContent={setContent}
              setNewPost={setNewPost}
              content={content}
            />
          ) : null}
          <div
            className={`py-5 px-4 flex flex-col border-b border-[${primaryColor}]`}
          >
            <div className={` flex justify-between items-center`}>
              {id && (
                <p
                  style={{ color: red }}
                  className={`text-sm font-medium cursor-pointer p-2 hover:bg-red-600/5 rounded-[8px]  duration-200`}
                  onClick={() => setIsOpenDeleteModal(true)}
                >
                  Move to Trash
                </p>
              )}
              <Button
                height={37}
                style={{
                  color: yellow,
                  fontFamily: 'Inter-Medium',
                  fontSize: 13,
                  textTransform: 'none',
                  borderRadius: 8,
                  boxShadow: 'none',
                  borderColor: yellow,
                }}
                component='span'
                variant='outlined'
                onClick={() => navigate('/pages')}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className={`py-5 px-4 border-b  border-[${primaryColor}]`}>
            <p className='text-sm'>Tags</p>
            <BlogTags tagsList={tags} setTagsList={setTags} />
          </div>
          <div className={`py-5 px-4 border-b border-[${primaryColor}]`}>
            <p className='text-sm'>Featured Image</p>
            {newPostMedia?.featureImage !== '' ? (
              <Box component='div'>
                <img
                  src={
                    newPostMedia?.featureImage !== '' &&
                    (newPostMedia?.featureFrom === 'link'
                      ? newPostMedia?.featureImage?.url
                      : URL.createObjectURL(newPostMedia?.featureImage))
                  }
                  alt={'Feature Image'}
                  className='rounded-lg h-[145px] w-[100%] mt-[15px] mb-[15px]'
                  style={{ objectFit: 'cover' }}
                />
                {newPostMediaError.type === 'featureImage' ? (
                  <p
                    style={{ color: red }}
                    className='text-[12px] w-full mb-[15px]'
                  >
                    {newPostMediaError?.message}
                  </p>
                ) : null}
                <div className='flex justify-between flex-row-reverse items-center'>
                  <p
                    style={{ color: red }}
                    className={`text-sm font-medium cursor-pointer p-2 hover:bg-red-600/5 rounded-[8px]  duration-200`}
                    onClick={() => {
                      if (id) {
                        setDeletedIds((prev) => [
                          ...prev,
                          newPostMedia?.featureImage?.id,
                        ]);
                      }
                      setNewPostMedia((pre) => ({
                        ...pre,
                        featureImage: '',
                      }));
                      setNewPostMediaError({ type: '', message: '' });
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      size='small'
                      sx={{ color: red }}
                    />
                    Remove
                  </p>
                  <label
                    htmlFor='file-input'
                    className={`border border-[${yellow}] text-[${yellow}] m-aut self-start cursor-pointer text-sm font-medium p-2 rounded-[8px]  duration-200`}
                  >
                    Replace Image
                    <input
                      accept='image/*'
                      id='file-input'
                      type='file'
                      className='hidden'
                      onChange={(e) => {
                        handleOnChangeFeatureImage(e?.target?.files);
                        if (id) {
                          setDeletedIds((prev) => [
                            ...prev,
                            newPostMedia?.featureImage?.id,
                          ]);
                        }
                      }}
                      onClick={() => {
                        setNewPostMediaError({ type: '', message: '' });
                      }}
                    />
                  </label>
                </div>
              </Box>
            ) : (
              <DragDropFile
                accept='image/*'
                onDrop={handleDropFeatureImage}
                removeImage={handleRemoveImage}
                selectedFiles={[]}
                onChange={handleOnChangeFeatureImage}
                onClick={(event) => {
                  event.target.value = '';
                  setNewPostMediaError({ type: '', message: '' });
                }}
                error={
                  newPostMediaError.type === 'featureImage' &&
                  newPostMediaError.message
                }
                showStaticFiles={true}
                hideErrorFn={() => {
                  setNewPostMediaError({
                    type: '',
                    message: '',
                  });
                }}
              />
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* TypoGraphy */}
        <Box display={'block'}>
          <div className={`py-5 px-4 border-b border-[${primaryColor}]`}>
            <p className='text-sm mb-[10px]'>Paragraph</p>
            <span style={{ color: gray }} className='text-[13px]'>
              Start with the basic building block of all narrative.
            </span>
          </div>
          <div className={`py-5 px-4 border-b border-[${primaryColor}]`}>
            <p className='text-sm mb-[10px]'>Typography</p>
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className='flex flex-col items-start justify-start px-4 h-[calc(100vh-131px)] overflow-auto'>
          <div className='w-full'>
            <TextField
              label={'Blog Meta Title'}
              type='text'
              name='blogTitle'
              style={{
                borderWidth: '1px',
                borderColor: yellow,
              }}
              value={newPost?.blogTitle}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div className='w-full'>
            <TextField
              label={'Blog Meta Keywords'}
              type='text'
              name='keywords'
              style={{
                borderWidth: '1px',
                borderColor: yellow,
              }}
              value={newPost?.keywords}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          <div className='w-full'>
            <TextField
              label={'Blog Meta Description'}
              type='text'
              name='blogMetaDescription'
              style={{
                borderWidth: '1px',
                borderColor: yellow,
              }}
              value={newPost?.blogMetaDescription}
              onChange={(e) => handleOnChange(e)}
            />
          </div>

          {/* PAGE LINKING */}
          <div className='w-full pt-4'>
            <PageLinking
              links={links}
              setLinks={setLinks}
              content={content}
              setContent={setContent}
            />
          </div>
        </div>
      </TabPanel>

      {/* Visibiblity PopOver */}
      <Popover
        id={visibilityId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            color: white,
          },
        }}
      >
        <Box bgcolor={secondaryColor} width={280} padding='15px' pb='40px'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography color={white} fontSize={17} fontFamily={'Inter-Medium'}>
              Visibility
            </Typography>
            <IconButton onClick={() => setAnchorEl(null)}>
              <CloseRoundedIcon sx={{ color: white }} fontSize='medium' />
            </IconButton>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              name='visibility'
              value='public'
              onChange={handleVisibilityChange}
              checked={Boolean(newPost?.visibility === 'public')}
            />
            <div className='flex  flex-col '>
              <span className='text-[13px] mt-[15px]'>Public</span>
              <p className={`text-[11px] text-[${gray}]`}>
                Visible to everyone.
              </p>
            </div>
          </Box>
          <Box display='flex' alignItems='center'>
            <Radio
              name='visibility'
              value='password_protected'
              onChange={handleVisibilityChange}
              checked={Boolean(newPost?.visibility === 'password_protected')}
            />
            <div className='flex  flex-col '>
              <span className='text-[13px] mt-[15px]'>Password protected</span>
              <p className={`text-[11px] text-[${gray}]`}>
                Only those the password can view this page.
              </p>
            </div>
          </Box>
          {newPost?.visibility === 'password_protected' &&
            newPost?.visibility !== '' && (
              <Box mt='10px'>
                <input
                  name='password'
                  placeholder='Enter Password'
                  className={`bg-transparent p-3 text-[12px] w-full !border-[${yellow}] border-[1px] rounded-[8px] `}
                  value={newPost?.password}
                  onChange={(e) =>
                    setNewPost((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                />
              </Box>
            )}
        </Box>
      </Popover>

      {/* Delete Blog Page Modal */}
      <DeleteBlogPage
        open={isOpenDeleteModal}
        data={blogData}
        close={closeDeleteModal}
        onDelete={handleOnDelete}
        loading={deleteLoading}
        error={deleteError}
      />
    </React.Fragment>
  );
}

export default BlogTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ fontFamily: 'Inter' }}
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
