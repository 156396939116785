import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ReactQuill, { Quill, editor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quil.custom.css';
import { red } from '../../utils/style/GlobalVariables';
import { parsedImagesFromContent } from '../../utils/utils';

const formats = [
  'alt',
  'id',
  'class',
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
];

function QuilEditor({
  content,
  setContent,
  images,
  setImages,
  deletedIds,
  setDeletedIds,
}) {
  const [currentImages, setCurrentImages] = useState([]);
  const [error, setError] = useState({
    isShow: false,
    type: '',
    message: '',
  });
  const quillRef = useRef(null);

  // IMAGE HANDLER FOR QUILL
  const imageHandler = useCallback((e) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        if (file && file.size > 5e6) {
          setError({
            isShow: true,
            type: 'image',
            message: 'The image must not be greater than 5MB.',
          });
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          const imgSrc = e.target.result;
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection(true);
          if (range) {
            quill.insertEmbed(range.index, 'image', imgSrc);
            quill.setSelection(range.index + 1, 0);
          }
        };
        reader.readAsDataURL(file);
      }
      setImages((file) => [...file, input.files[0]]);
    };
  }, []);

  // MODULES
  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ header: 1 }],
          [{ header: 2 }],
          [{ header: 3 }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: '' }],
          [{ align: 'right' }],
          [{ align: 'center' }],
          [{ align: 'justify' }],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [imageHandler]
  );

  // USEEFFECT FOR HIDE ERRORS
  useEffect(() => {
    if (error.isShow) {
      setTimeout(() => {
        setError({
          isShow: false,
          type: '',
          message: '',
        });
      }, 3000);
    }
  }, [error.isShow, error.message]);

  // USEEFFECT FOR CURRENT IMAGES
  useEffect(() => {
    const images = parsedImagesFromContent(content);
    setCurrentImages(images?.map((image) => image?.alt));
  }, [content]);

  // USEEFFECT FOR DELETED IMAGES
  useEffect(() => {
    const newImages = parsedImagesFromContent(content);
    const imagesIds = newImages.map((image) => image?.alt);
    const removedImages = currentImages.filter(
      (id) => !imagesIds?.includes(id)
    );
    if (removedImages.length > 0) {
      setDeletedIds((prev) => [...prev, ...removedImages]);
    }
  }, [content]);

  return (
    <React.Fragment>
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={content}
        onChange={(value) => setContent(value)}
        modules={modules}
        formats={formats}
        style={{
          fontSize: '14px',
          backgroundColor: '#1A1A22',
          color: 'white',
          border: 'none',
          border: '0px',
        }}
        className='max-h-[445px] min-h-[360px] overflow-scroll'
        placeholder={'Write something...'}
        scrollingContainer={'body'}
      />
      {error.isShow ? (
        <p style={{ color: red }} className='text-sm'>
          {error.message}
        </p>
      ) : null}
    </React.Fragment>
  );
}

export default QuilEditor;
