import React, { useRef } from 'react';
import ReactQuill, { Quill, editor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quil.custom.css';
function SimpleEdior({ content, setContent }) {
  const quillRef = useRef(null);
  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ header: 1 }],
          [{ header: 2 }],
          [{ header: 3 }],
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ align: '' }],
          [{ align: 'right' }],
          [{ align: 'center' }],
          [{ align: 'justify' }],
          ['clean'],
        ],
      },
    }),
    []
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'link',
    'align',
  ];

  return (
    <React.Fragment>
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={content}
        onChange={(value) => setContent(value)}
        modules={modules}
        formats={formats}
        style={{
          fontSize: '14px',
          backgroundColor: '#1A1A22',
          color: 'white',
          border: 'none',
          border: '0px',
        }}
        className=' min-h-[360px]'
        placeholder={'Write something...'}
      />
    </React.Fragment>
  );
}

export default SimpleEdior;
