import { useEffect, useState } from 'react';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded';
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import { gray } from './style/GlobalVariables';
import { Heading1, Heading2, Heading3 } from '../assets';

export const toolBar = [
  {
    exeCmd: 'insertImage',
    icon: <ImageRoundedIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Image',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'insertVideo',
    icon: <VideocamRoundedIcon fontSize='medium' sx={{ color: gray }} />,
    tooltip: 'Video',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'createLink',
    icon: <InsertLinkRoundedIcon fontSize='medium' sx={{ color: gray }} />,
    tooltip: 'Link',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'bold',
    icon: <FormatBoldRoundedIcon fontSize='medium' sx={{ color: gray }} />,
    tooltip: 'Bold',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'italic',
    icon: <FormatItalicRoundedIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Italic',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'underline',
    icon: <FormatUnderlinedRoundedIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Underline',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'heading1',
    icon: <Heading1 className='text-[#85878D] size-[19px] mt-1' />,
    tooltip: 'Heading 1',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'heading2',
    icon: <Heading2 className='text-[#85878D] size-[19px] mt-1' />,
    tooltip: 'Heading 2',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'heading3',
    icon: <Heading3 className='text-[#85878D] size-[19px] mt-1' />,
    tooltip: 'Heading 3',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'justifyLeft',
    icon: <FormatAlignLeftRoundedIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Align Left',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'justifyCenter',
    icon: <FormatAlignCenterIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Align Left',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'justifyRight',
    icon: <FormatAlignRightIcon fontSize='small' sx={{ color: gray }} />,
    tooltip: 'Align Right',
    show: false,
    absence: null,
    onClick: () => {}, // Optional,
  },
  {
    exeCmd: 'insertUnorderedList',
    icon: (
      <FormatListBulletedRoundedIcon fontSize='small' sx={{ color: gray }} />
    ),
    tooltip: 'Bulleted List',
    show: false,
    absence: '',
    onClick: () => {}, // Optional,
  },
];

export const imageSizeList = [
  { name: 'Default', value: 'default', id: 1 },
  { name: 'Custom', value: 'custom', id: 2 },
];

export const fontSizeList = [
  { label: 'Size', value: '' },
  { label: '8px', value: '8px' },
  { label: '10px', value: '10px' },
  { label: '12px', value: '12px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '20px', value: '20px' },
  { label: '22px', value: '22px' },
  { label: '24px', value: '24px' },
  { label: '28px', value: '28px' },
  { label: '30px', value: '30px' },
  { label: '32px', value: '32px' },
  { label: '34px', value: '34px' },
  { label: '36px', value: '36px' },
  { label: '38px', value: '38px' },
  { label: '40px', value: '40px' },
  { label: '42px', value: '42px' },
  { label: '44px', value: '44px' },
  { label: '46px', value: '46px' },
  { label: '48px', value: '48px' },
];

export function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const day = today.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function debounce(func, delay) {
  let timeoutId;

  return function (...args) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

// Debouce for Delay API Call
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// USE OUTSIDE CLICK
export function useOutsideClick(ref, handler) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
}

// REPLACE BASE64 OF IMAGES TO URLS
export function replaceBase64ToUrlContent(content, orignalURls) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = Array.from(doc.querySelectorAll('img'));
  const originalImages = orignalURls?.filter(
    (img) => img?.featured !== 'featured'
  );
  // REPLACE BASE64 TO URLS
  images.forEach((img, index) => {
    let url;
    url = originalImages[index];
    if (url && url?.featured !== 'featured') {
      img.src = url?.url;
      img.setAttribute('id', url?.id);
      img.setAttribute('alt', url?.id);
    }
  });
  return doc.body.innerHTML;
}

// REPLACE BASE64 OF IMAGES TO URLS
export function replaceBase64ToLocalURL(content) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = Array.from(doc.querySelectorAll('img'));
  // REPLACE BASE64 TO URLS
  images.forEach((img, index) => {
    img.src = 'http://localhost:3000/';
  });
  return doc.body.innerHTML;
}

// REPLACE BASE64 OF IMAGES TO URLS
export function parsedImagesFromContent(content) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = Array.from(doc.querySelectorAll('img'));
  return images;
}

//  SLUGIFY
export const slugify = (text) =>
  text?.toLowerCase()?.replace(/\s+/g, '-')?.trim();
