import SimpleEdior from './Editor/SimpleEdior';

function RichTextInput({ value, setValue }) {
  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData('text/html');

    if (html) {
      // Create a temporary container to parse HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Update text color to white
      tempDiv.querySelectorAll('*').forEach((node) => {
        node.style.color = 'white';
      });

      // Insert the modified content
      const modifiedHtml = tempDiv.innerHTML;
      document.execCommand('insertHTML', false, modifiedHtml);
    } else {
      // Fallback for plain text
      const text = clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text);
    }
  };

  return <SimpleEdior content={value} setContent={setValue} />;
}

export default RichTextInput;
