import { useCallback, useEffect, useRef, useState } from 'react';
// Library Imports
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Local Imports
import {
  primaryColor,
  secondaryColor,
  white,
  yellow,
} from '../../utils/style/GlobalVariables';
import Button from '../button';
import ApiController from '../../utils/network/api';
import { useDebounce } from '../../utils/utils';

const PageLinking = ({ links, setLinks, content, setContent }) => {
  // STATES
  const taglistRef = useRef(null);
  const [existingLinkList, setExistingLinkList] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // States and Functions For Visibility Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [keywordLink, setKeywordLink] = useState({
    keyword: null,
    link: null,
  });
  const open = Boolean(anchorEl);
  const visibilityId = open ? 'simple-popover' : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setError('');
    setKeywordLink({});
    setExistingLinkList([]);
  };

  // HANDLE ADD LINKS
  const addLinks = () => {
    // Validate if keyword exists in the content
    if (
      !content?.toLowerCase()?.includes(keywordLink?.keyword?.toLowerCase())
    ) {
      setError("Keyword doesn't exist in the description text");
      return;
    }

    if (
      !/^(?=.*[a-zA-Z])[\w\s!@#$%^&*()\-+=\[\]{};:'",.<>?\/\\|`~]+$/.test(
        keywordLink?.keyword
      )
    ) {
      setError('You cannot add a link to special characters');
      return;
    }

    // Check if the keyword is already inside an anchor tag
    const existingAnchorRegex = new RegExp(
      `<a\\s+[^>]*href=["'].*?["'][^>]*>${keywordLink?.keyword}</a>`,
      'gi'
    );
    if (existingAnchorRegex.test(content)) {
      setError('This keyword is already linked');
      return;
    }

    const wordRegex = new RegExp(
      `(?<!<a[^>]*>)\\b(${keywordLink?.keyword})\\b(?![^<]*</a>)`,
      'gi'
    );
    const isWordValid = wordRegex.test(content);
    if (!isWordValid) {
      setError("Keyword doesn't exist as a standalone word in the description");
      return;
    }

    const alreadyExist = links?.some(
      (link) =>
        link.keyword === keywordLink?.keyword &&
        link?.link === keywordLink?.link
    );

    if (alreadyExist) {
      setError('This keyword with the same link already exists');
      return;
    }

    // Replace keyword with properly formatted anchor tag
    const updatedContent = content.replace(wordRegex, (match, keyword) => {
      return `<a href="${keywordLink?.link}" target="_blank" rel="noopener noreferrer">${keyword}</a>`;
    });

    setContent(updatedContent);
    setLinks((prev) => [...prev, keywordLink]);
    setKeywordLink({});
    setAnchorEl(null);
  };

  // HANDLE REMOVE LINK
  const handleRemoveLink = (linkToRemove) => {
    setLinks((prev) =>
      prev.filter((link) => link.keyword !== linkToRemove.keyword)
    );
    const linkRegex = new RegExp(
      `<a\\s+[^>]*href=["']${linkToRemove?.link}["'][^>]*>(.*?)</a>`,
      'gi'
    );
    const updatedContent = content.replace(linkRegex, (match, innerContent) => {
      return innerContent;
    });
    setContent(updatedContent);
  };
  const debouncedKeyword = useDebounce(keywordLink?.keyword, 500);

  // FETCH LINK LISt
  const fetchLinkList = useCallback(() => {
    setLoading(true);
    if (debouncedKeyword) {
      ApiController.getLinkListingCall(debouncedKeyword, (response) => {
        if (response.success) {
          const links = response?.data?.map((link) => {
            return {
              keyword: link?.word,
              link: link?.link,
            };
          });
          setExistingLinkList(links);
          setLoading(false);
        }
        setLoading(false);
      });
      setLoading(false);
    }
    setLoading(false);
  }, [debouncedKeyword, ApiController]);

  useEffect(() => {
    if (keywordLink?.keyword !== null) {
      fetchLinkList();
    }
  }, [fetchLinkList]);

  return (
    <div className='w-full flex flex-col gap-1'>
      <div className='flex justify-between items-center'>
        <p className='text-sm'>Page Linking</p>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderWidth: '1px',
            borderRadius: '4px',
            borderColor: yellow,
            padding: '1px 8px',
          }}
        >
          <Typography
            className={`text-xs cursor-pointer`}
            color={yellow}
            onClick={handleClick}
            fontSize={12}
          >
            Add Link
          </Typography>
        </div>
      </div>

      {links?.length > 0 ? (
        <Box
          color={white}
          display='flex'
          flexWrap='wrap'
          gap='5px'
          marginBottom='8px'
          marginTop='8px'
          maxHeight='200px'
          overflow='scroll'
          ref={taglistRef}
        >
          {links?.map((link, index) => {
            return (
              <Chip
                variant='filled'
                size='small'
                deleteIcon={<CloseRoundedIcon />}
                key={index}
                label={link?.keyword}
                onDelete={() => handleRemoveLink(link)}
                sx={{
                  color: white,
                  borderRadius: '4px',
                  backgroundColor: primaryColor,
                  '& .MuiChip-deleteIcon': {
                    color: white,
                    '&:hover': {
                      color: yellow,
                    },
                  },
                }}
              />
            );
          })}
        </Box>
      ) : (
        <p className='text-sm'>No Link Found!</p>
      )}

      {/* Visibiblity PopOver */}
      <Popover
        id={visibilityId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            color: white,
          },
        }}
      >
        <Box bgcolor={secondaryColor} width={280} padding='15px' pb='20px'>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography color={white} fontSize={17} fontFamily={'Inter-Medium'}>
              Add Link
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRoundedIcon sx={{ color: white }} fontSize='medium' />
            </IconButton>
          </Box>

          <div className='w-full flex flex-col gap-1 mb-3'>
            <div className='relative'>
              <p className='text-[11px] font-["Inter-Regular"] py-1'>
                Target Keyword
              </p>
              <input
                name={`keyword`}
                value={keywordLink?.keyword}
                autocomplete='off'
                className={`bg-transparent p-3 text-[12px] w-full !border-[${yellow}] border-[1px] rounded-[8px] w-full `}
                onChange={(e) => {
                  setError('');
                  setKeywordLink((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }));
                }}
                onFocus={() => setOpenDropdown(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setOpenDropdown(false);
                  }, 100);
                }}
              />
              {loading ? (
                <p className='text-green-500 mt-1 text-xs'>Loading...</p>
              ) : null}
              <div
                className={`${
                  openDropdown && existingLinkList?.length > 0
                    ? 'flex'
                    : 'hidden'
                } absolute top-18 left-0 w-60 max-h-32 overflow-y-auto bg-[#0F0F14] flex-col rounded-lg z-50 py-2`}
              >
                {existingLinkList?.map((link, index) => {
                  return (
                    <div
                      key={index}
                      className='text-white hover:bg-[#1B1B23] cursor-pointer py-0.5 px-2'
                      onClick={() => {
                        setKeywordLink({
                          link: link?.link,
                          keyword: link?.keyword,
                        });
                        setOpenDropdown(false);
                      }}
                    >
                      {link?.keyword}
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <p className='text-[11px] font-["Inter-Regular"] py-1'>
                Target Keyword Link
              </p>
              <input
                name={`link`}
                value={keywordLink?.link}
                autocomplete='off'
                className={`bg-transparent p-3 text-[12px] w-full !border-[${yellow}] border-[1px] rounded-[8px] w-full `}
                onChange={(e) => {
                  setError('');
                  setKeywordLink((prev) => ({ ...prev, link: e.target.value }));
                }}
              />
            </div>
          </div>

          <Button
            height={37}
            style={{
              color:
                !keywordLink?.keyword || !keywordLink?.link ? 'gray' : yellow,
              fontFamily: 'Inter-Medium',
              fontSize: 13,
              textTransform: 'none',
              borderRadius: 8,
              boxShadow: 'none',
              borderColor:
                !keywordLink?.keyword || !keywordLink?.link ? 'gray' : yellow,
            }}
            backgroundColor={secondaryColor}
            component='span'
            variant='outlined'
            onClick={() => addLinks()}
            disabled={!keywordLink?.keyword || !keywordLink?.link}
          >
            Add Link
          </Button>
          {error ? <p className='text-xs text-red-500 mt-1'>{error}</p> : null}
        </Box>
      </Popover>
    </div>
  );
};

export default PageLinking;
