import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  MenuItem,
  Autocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckIcon from '@mui/icons-material/Check';
// LOCAL IMPORTS
import {
  primaryColor,
  white,
  yellow,
} from '../../../../../../utils/style/GlobalVariables';
import ApiController from '../../../../../../utils/network/api';

function BlogTags({ tagsList, setTagsList }) {
  // STATES
  const taglistRef = useRef(null);
  const [existingTagsList, setExistingTagsList] = useState([]);

  // FETCH TAGSLIST
  const fetchTagsList = () => {
    ApiController.getTagsListingCall('', (response) => {
      if (response.success) {
        const tags = response?.data?.tags;
        setExistingTagsList(tags);
      }
    });
  };

  useEffect(() => {
    fetchTagsList();
  }, []);
  return (
    <React.Fragment>
      <Box>
        {tagsList?.length > 0 && (
          <Box
            color={white}
            display='flex'
            flexWrap='wrap'
            gap='5px'
            marginBottom='8px'
            marginTop='8px'
            maxHeight='200px'
            overflow='scroll'
            ref={taglistRef}
          >
            {tagsList?.map((tag, index) => {
              return (
                <Chip
                  variant='filled'
                  size='small'
                  deleteIcon={<CloseRoundedIcon />}
                  key={index}
                  label={tag}
                  onDelete={() =>
                    setTagsList((prev) => prev.filter((t) => t !== tag))
                  }
                  sx={{
                    color: white,
                    borderRadius: '4px',
                    backgroundColor: primaryColor,
                    '& .MuiChip-deleteIcon': {
                      color: white,
                      '&:hover': {
                        color: yellow,
                      },
                    },
                  }}
                />
              );
            })}
          </Box>
        )}
        <Autocomplete
          sx={{
            m: 1,
            color: 'white',
            border: `1px solid ${yellow}`,
            borderRadius: '8px',
            '& .css-hzaytx-MuiInputBase-root-MuiOutlinedInput-root': {
              color: white,
            },
            '& .css-4duybk-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input':
              {
                boxShadow: 'none',
              },
            '& .css-i4bv87-MuiSvgIcon-root': {
              color: yellow,
            },
            input: {
              boxShadow: 'none !important',
              color: 'white',
            },
            svg: {
              fill: `${yellow}`,
            },
          }}
          color={'success'}
          noOptionsText
          options={existingTagsList}
          getOptionLabel={(option) => option}
          size='small'
          disableCloseOnSelect
          renderOption={(props, option, { selected }) => (
            <MenuItem
              key={option}
              value={option}
              sx={{ justifyContent: 'space-between' }}
              {...props}
              onClick={() => {
                if (!tagsList.includes(option)) {
                  setTagsList((prev) => [...prev, option]);
                }
              }}
            >
              {option}
              {tagsList.includes(option) && (
                <CheckIcon sx={{ color: yellow }} />
              )}
            </MenuItem>
          )}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              placeholder='Enter tag'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (
                    e.target.value.trim() !== '' &&
                    !tagsList.includes(e.target.value.trim())
                  ) {
                    setTagsList([...tagsList, e.target.value.trim()]);
                  }
                }
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.target.value = '';
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&:hover': {
                    borderRadius: '8px',
                    boxShadow: 'none',
                  },
                  '&.Mui-focused': {
                    borderRadius: '8px',
                    boxShadow: 'none',
                  },
                },
              }}
            />
          )}
        />
      </Box>
    </React.Fragment>
  );
}

export default BlogTags;
